import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {
  ApiCallService,
  Batch,
  Mutation,
  Translations
} from '../api-call.service';

interface InventoryParams {
  id: number;
  batch?: Batch;
}

interface ProductParams {
  id: number;
  batch?: Batch;
}

interface ListInventoryParams extends InventoryParams {
  pricing?: boolean;
  with?: string[];
  paginate?: boolean;
  mutations?: Mutation[];
}

interface CreateInventoryParams extends InventoryParams {
  status?: boolean;
  code?: string;
  order?: number;
  translations?: Translations;
}

interface UpdateInventoryParams extends InventoryParams {
  status?: boolean;
  order?: number;
  translations?: Translations;
}

interface SaveInventoryParams {
  product_id?: number;
  warehouse_id?: number;
  quantity?: number;
}

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private api: ApiCallService) { }

  public list(params?: ListInventoryParams, page?) {
    return this.api.call('inventory.warehouse.list', params, page);
  }

  public create(params?: CreateInventoryParams) {
    return this.api.call('inventory.warehouse.store', params);
  }

  public update(params?: UpdateInventoryParams) {
    return this.api.call('inventory.warehouse.update', params);
  }

  public view(params?: InventoryParams) {
    return this.api.call('inventory.warehouse.view', params);
  }

  public delete(params?: InventoryParams) {
    return this.api.call('inventory.warehouse.delete', params);
  }

  public save(params?: SaveInventoryParams) {
    return this.api.call('inventory.inventory.save', params);
  }

  public productList(params?: ProductParams, page?) {
    const result = this.api.call('product.product.list', params, page);
    return result.pipe(map((response: any) => {
      response.result.data.forEach((item) => {
        const warehouses = {};
        if (item.inventory.length !== 0 ) {
          item.inventory.forEach((inventory) => {
              if (!warehouses.hasOwnProperty(inventory.warehouse.code)){
                warehouses[inventory.warehouse.name] =
                  {
                    quantity: inventory.quantity,
                    reserve: inventory.reserve
                  };
              }
            }
          );
        }
        item.warehouses = warehouses;
      });

      return response;
    }));
  }

}
