import { Injectable } from '@angular/core';
import { Mutation, ApiCallService, Batch } from '../api-call.service';
import { DatePipe } from '@angular/common';

export interface PriceParams {
  id: number;
  batch?: Batch;
}
export interface ListParams {
  with: string[];
  mutations: Mutation[];
  batch?: Batch;
}

export interface CreatePriceParams {
  pricelist_id: number;
  value: number;
  starts_at?: string;
  batch?: Batch;
}

export interface ViewPriceParams extends PriceParams {
  with?: string[];
}

export interface UpdatePriceParams extends PriceParams {
  pricelist_id?: number;
  value?: number;
  starts_at?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PricingPriceService {

  constructor(private api: ApiCallService) { }

  public list(params?: ListParams, page?) {
    return this.api.call('pricing.price.list', params, page);
  }

  public create(params: CreatePriceParams) {
    params.starts_at = new DatePipe('en-GB').transform(params.starts_at, 'yyyy-MM-dd');
    return this.api.call('pricing.price.store', params);
  }

  public view(params: ViewPriceParams) {
    return this.api.call('pricing.price.view', params);
  }

  public update(params: UpdatePriceParams) {
    params.starts_at = new DatePipe('en-GB').transform(params.starts_at, 'yyyy-MM-dd');
    return this.api.call('pricing.price.update', params);
  }

  public delete(params: PriceParams) {
    return this.api.call('pricing.price.delete', params);
  }
}
