import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ApiCallService, Batch } from '../api-call.service';

interface AddItemParams {
  product_id: number;
  quantity: number;
  batch?: Batch;
}
interface RemoveItemParams {
  id: number;
  quantity: number;
  batch?: Batch;
}

interface LoadCartParams {
  hash: string;
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})
export class CartService {
  constructor(private api: ApiCallService) { }

  public addItem(params?: AddItemParams) {
    return this.api.call('order.cart.addItem', params, null);
  }
  public removeItem(params?: RemoveItemParams) {
    return this.api.call('order.cart.removeItem', params, null);
  }
  public loadCart(params?: LoadCartParams) {
    let batch;
    // if hash is null don't send the request
    if (params.hash === null || params.hash === 'null') {
      return of();
    }
    if (typeof params !== 'undefined' && typeof params.batch !== 'undefined') {
      batch = params.batch;
      delete params.batch;
    }
    return this.api.call('order.cart.loadCart', params);
  }
}
