import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService, JsonRpcResponse, JsonRpcError } from './config.service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

export interface Translation {
  [key: string]: string;
}

export interface Translations {
  [key: string]: Translation;
}

export interface Mutation {
  constraint: string;
  params: Array<string | Mutation>;
}

export interface File {
  filename: string;
  content: string;
}

export interface Batch {
  name: string;
  stage: 'finish' | undefined;
}

export interface SearchParams {
  with?: string[];
  query?: string;
  filters?: { [key: string]: any };
  paginate?: boolean;
  batch?: Batch;
}

export class JsonRpcRequest {
  jsonrpc: string;
  method: string;
  params?: object;
  constructor(version: string, method: string, params?: object) {
    this.jsonrpc = version;
    this.method = method;
    if (typeof params !== 'undefined') {
      this.params = params;
    }
  }
}

interface Params {
  [key: string]: any;
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})
export class ApiCallService {
  private batchItems: { [key: string]: JsonRpcRequest[] } = {};
  private batchResponses: { [key: string]: Subject<JsonRpcResponse | JsonRpcError>[] } = {};

  constructor(private http: HttpClient, private config: ConfigService) { }

  private buildUrl(page?: number, apName?: string): string {
    let url = this.config.getAccessPoint(apName).url.protocol + '://'
      + this.config.getAccessPoint(apName).url.hostname + '/'
      + this.config.getAccessPoint(apName).url.path;
    if (typeof page !== 'undefined') {
      url += '?page=' + page;
    }

    return url;
  }

  private prepareRequest(
    method: string,
    params?: object,
    batch?: Batch
  ): JsonRpcRequest | Observable<JsonRpcResponse | JsonRpcError> {
    let request;
    if (typeof batch !== 'undefined') {
      if (typeof batch.stage === 'undefined') {
        if (typeof this.batchItems[batch.name] === 'undefined') {
          this.batchItems[batch.name] = [];
        }
        if (typeof this.batchResponses[batch.name] === 'undefined') {
          this.batchResponses[batch.name] = [];
        }
        this.batchItems[batch.name].push(new JsonRpcRequest('2.0', method, params));
        const last = this.batchResponses[batch.name].push(new Subject());
        return this.batchResponses[batch.name][last - 1];
      } else if (
        batch.stage === 'finish'
        && this.batchItems[batch.name].length > 0
      ) {
        this.batchItems[batch.name].push(new JsonRpcRequest('2.0', method, params));
        request = this.batchItems[batch.name];
        this.batchItems[batch.name] = [];
      }
    } else {
      request = new JsonRpcRequest('2.0', method, params);
    }

    return request;
  }
  call(
    method: string,
    params?: Params,
    page?: number,
    apName?: string
  ): Observable<JsonRpcResponse | JsonRpcError> {
    const url = this.buildUrl(page, apName);

    let batch: Batch;
    if (typeof params !== 'undefined' && typeof params.batch !== 'undefined') {
      batch = params.batch;
      delete params.batch;
    }

    const request = this.prepareRequest(method, params, batch);
    if (request instanceof Observable) {
      return request;
    }

    const response = this.http.post(url, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.config.getAccessPoint(apName).token
      }),
      withCredentials: true
    })
      .pipe(
        catchError(err => throwError(err)),
        map((rs: any) => {
          if (Array.isArray(rs)) {
            let key = 0;
            for (const resp of rs) {
              if (key < rs.length - 1) {
                let item;
                if (typeof resp.error === 'undefined') {
                  item = new JsonRpcResponse(resp.jsonrpc);
                  if (typeof resp.result !== 'undefined') {
                    item.result = resp.result;
                  }
                } else {
                  item = new JsonRpcError(
                    resp.error.code,
                    resp.error.message,
                    resp.error.data
                  );
                  this.batchResponses[batch.name][key].error(item);
                }
                this.batchResponses[batch.name][key].next(item);
              } else {
                delete this.batchResponses[batch.name];
                return this.respond(resp);
              }
              key++;
            }
          } else {
            return this.respond(rs);
          }
        }));

    return response;
  }

  private respond(rs) {
    if (typeof rs.error === 'undefined') {
      const ApiResponse = new JsonRpcResponse(rs.jsonrpc);
      ApiResponse.result = rs.result;
      return ApiResponse;
    } else {
      throw new JsonRpcError(
        rs.error.code,
        rs.error.message,
        rs.error.data
      );
    }
  }
}
