import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiCallService } from '../api-call.service';

interface SettingsParams {
  id: number;
}

interface ListSettingsParams {
  type: 'tree' | 'list' | null | undefined;
}

interface CreateSettingsParams {
  module: string;
  key: string;
  group: string;
  value: string;
  description: string;
}

interface UpdateSettingsParams extends SettingsParams, CreateSettingsParams { }

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private api: ApiCallService) { }

  public list(params: ListSettingsParams, page?) {
    return this.api.call('app.setting.list', params, page).pipe(
      map(response => {
        return {
          result: {
            data: params.type === 'tree' ? this.flatToTree((response as any).result) : response
          }
        };
      })
    );
  }

  public update(params: UpdateSettingsParams) {
    return this.api.call('app.setting.update', params);
  }

  public create(params: CreateSettingsParams) {
    return this.api.call('app.setting.store', params);
  }

  public delete(params: SettingsParams) {
    return this.api.call('app.setting.delete', params);
  }

  // Transform the flat structure of the settings into tree
  // To be used with TreeList Component
  // Group by module, then by group
  private flatToTree(data: any[]): any[] {
    const modules = [];
    const groups = [];

    data.forEach(item => {
      if (!modules.find(m => m.module === item.module)) {
        modules.push({
          module: item.module,
          title: `${item.module}`,
          children: []
        });
      }

      if (!groups.find(g => g.group === item.group && g.module === item.module)) {
        groups.push({
          group: item.group,
          module: item.module,
          title: `${item.group}`,
          children: []
        });
      }
    });

    modules.forEach(module => {
      groups.forEach(group => {
        if (module.module === group.module) {
          module.children.push(group);
        }
      });
    });

    data.forEach(item => {
      groups.forEach(group => {
        if (item.module === group.module &&
            item.group === group.group) {
          group.children.push({
            ...item,
            title: `${item.description}`,
          });
        }
      });
    });

    return modules;
  }
}
