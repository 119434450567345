import { Injectable } from '@angular/core';
import { Batch, Mutation, ApiCallService } from '../api-call.service';

interface ListMethodsParams {
  paginate?: boolean;
  mutations?: Mutation[];
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private api: ApiCallService) { }

  public listMethods(params?: ListMethodsParams, page?) {
    return this.api.call('payment.paymentMethod.list', params, page);
  }
}
