import { Component, OnInit } from '@angular/core';
import { SetupService } from 'ng-viv-ux';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'vexai';
  public logo: string;

  constructor(private setup: SetupService) {
    this.setup.init();
  }

  ngOnInit(): void { }
}
