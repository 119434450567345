import { Injectable } from '@angular/core';

export interface Url {
  protocol: string;
  hostname: string;
  path: string;
}

interface AccessPoint {
  url: Url | undefined;
  headers?: {[key: string]: any | any[]} | undefined;
  token?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private accessPoints: {[key:string]: AccessPoint} = {};
  
  public setAccessPoint(accessPoint: AccessPoint, name = 'default') {
    this.accessPoints[name] = accessPoint;
  }
  public getAccessPoint(name = 'default') {
    return this.accessPoints[name];
  }
}

export class JsonRpcResponse {
  jsonrpc: string;
  result?: any;
  constructor(version: string, result?: object) {
    this.jsonrpc = version;
    if (typeof result !== 'undefined') {
      this.result = result;
    }
  }
}

export class JsonRpcError extends Error {
  code: number;
  data: object;
  constructor(code: number, message: string, data: object) {
    super(message);
    this.code = code;
    this.name = 'JsonRpcError';
    this.data = data;
  }
}
