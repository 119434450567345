import { Injectable } from '@angular/core';
import { Mutation } from '../api-call.service';
import { ApiCallService, Batch } from '../api-call.service';

export interface ListSchemasParams {
  with: string[];
  mutations: Mutation[];
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})
export class PricingSchemaService {

  constructor(private api: ApiCallService) { }

  public list(params?: ListSchemasParams) {
    return this.api.call('pricing.schema.list', params);
  }
}
