import { Injectable } from '@angular/core';
import { ApiCallService, Batch } from '../api-call.service';

interface RoleParams {
  id: number;
  batch?: Batch;
}

interface ViewRoleParams extends RoleParams {
  with?: Array<string>;
}
interface CreateRoleParams {
  key: string;
  attachPermissions?: Array<number>;
  detachPermissions?: Array<number>;
  attachUsers?: Array<number>;
  detachUsers?: Array<number>;
  [key: string]: any;
  batch?: Batch;
}

interface UpdateRoleParams extends RoleParams {
  attachPermissions?: Array<number>;
  detachPermissions?: Array<number>;
  attachUsers?: Array<number>;
  detachUsers?: Array<number>;
  [key: string]: any;
  batch?: Batch;
}

interface RoleListParams {
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private api: ApiCallService) { }

  public list(params?: RoleListParams, page?: number) {
    return this.api.call('access.role.list', undefined, page);
  }
  public view(params: ViewRoleParams) {
    return this.api.call('access.role.view', params);
  }
  public update(params: UpdateRoleParams) {
    return this.api.call('access.role.update', params);
  }
  public create(params: CreateRoleParams) {
    return this.api.call('access.role.store', params);
  }
  public delete(params: RoleParams) {
    return this.api.call('access.role.delete', params);
  }
}
