import { Injectable } from '@angular/core';
import { ApiCallService } from '../api-call.service';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class DealehomeService extends BaseService {
  protected methodPrefix = 'dealehome.dealehome';

  constructor(protected api: ApiCallService) {
    super(api);
    return this.proxy();
  }
}
