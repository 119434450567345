import { Injectable } from '@angular/core';
import { ApiCallService, Batch } from '../api-call.service';

export interface UploadImageParams {
  file: File;
  batch?: Batch;
}
export interface DeleteImageParams {
  url: string;
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalImagesService {

  constructor(private api: ApiCallService) { }

  public uploadImage(params: UploadImageParams) {
    return this.api.call('app.globalImages.uploadImage', params);
  }
  public listImages(params?: { batch?: Batch }) {
    return this.api.call('app.globalImages.listImages');
  }
  public deleteImage(params: DeleteImageParams) {
    return this.api.call('app.globalImages.deleteImage', params);
  }
}
