import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ApiCallService, Mutation, Translations, Batch } from '../api-call.service';

interface ListPromotionParams {
  mutations: Mutation;
  with?: string[];
  paginate: boolean;
  batch?: Batch;
}

interface PromotionParams {
  id: number;
  batch?: Batch;
}

interface ViewPromotionParams extends PromotionParams {
  with?: string[];
}

interface Condition {
  type: string;
  value: string;
  quantity?: number;
  amount?: number;
  deviation?: number;
}

interface Effect {
  type: string;
  value: string;
  discount_quantity?: number;
  discount_amount?: number;
  deviation?: number;
}

interface CreatePromotionParams {
  status?: boolean;
  from: Date | string;
  to: Date | string;
  translations: Translations;
  conditions: Condition[];
  effects: Effect[];
  batch?: Batch;
}

interface UpdatePromotionParams extends PromotionParams {
  status?: boolean;
  from?: Date | string;
  to?: Date | string;
  translations?: Translations;
  conditions?: Condition[];
  effects?: Effect[];
}

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(private api: ApiCallService) { }

  public list(params?: ListPromotionParams, page?) {
    return this.api.call('promotion.promotion.list', params, page);
  }
  public view(params: ViewPromotionParams) {
    return this.api.call('promotion.promotion.view', params);
  }
  public create(params: CreatePromotionParams) {
    params.from = new DatePipe('en-GB').transform(params.from, 'yyyy-MM-dd HH:mm:ss');
    params.to = new DatePipe('en-GB').transform(params.to, 'yyyy-MM-dd HH:mm:ss');
    return this.api.call('promotion.promotion.store', params);
  }
  public update(params: UpdatePromotionParams) {
    params.from = new DatePipe('en-GB').transform(params.from, 'yyyy-MM-dd HH:mm:ss');
    params.to = new DatePipe('en-GB').transform(params.to, 'yyyy-MM-dd HH:mm:ss');
    return this.api.call('promotion.promotion.update', params);
  }
  public getOrderItemsToAdd(params: PromotionParams) {
    return this.api.call('promotion.promotion.getOrderItemsToAdd', params);
  }
  public delete(params: PromotionParams) {
    return this.api.call('promotion.promotion.delete', params);
  }
}
