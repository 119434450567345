import { Injectable } from '@angular/core';
import { ApiCallService, File, Translations, Batch } from '../api-call.service';


export interface DocumentParams {
  id: number;
  batch?: Batch;
}

export interface ViewDocumentParams extends DocumentParams {
  with: string[];
}

export interface CreateDocumentParams {
  file: File;
  categories: number | number[];
  translations: Translations;
  [key: string]: any;
  batch?: Batch;
}

export interface ListDocumentParams {
  search?: string;
  paginate?: boolean;
  batch?: Batch;
}

export interface UpdateDocumentParams extends DocumentParams, CreateDocumentParams { }

@Injectable({
  providedIn: 'root'
})

export class DocumentService {

  constructor(private api: ApiCallService) { }

  public list(params?: ListDocumentParams, page?) {
    return this.api.call('document.document.list', params, page);
  }

  public create(params: CreateDocumentParams) {
    return this.api.call('document.document.store', params);
  }

  public view(params: ViewDocumentParams) {
    return this.api.call('document.document.view', params);
  }

  public update(params: UpdateDocumentParams) {
    return this.api.call('document.document.update', params);
  }

  public delete(params: DocumentParams) {
    return this.api.call('document.document.delete', params);
  }

  public file(params: DocumentParams) {
    return this.api.call('document.document.file', params);
  }
}
