import { Injectable } from '@angular/core';
import { ApiCallService, Translations, Mutation, Batch } from '../api-call.service';

export interface PublishedLabelParams {
  locale: string;
  batch?: Batch;
}

export interface StoreLabelParams {
  module: string;
  key: string;
  translations: Translations;
  batch?: Batch;
}

export interface UpdateLabelParams {
  id: number;
  module: string;
  key: string;
  translations: Translations;
  batch?: Batch;
}

export interface LabelParams {
  id: number;
  batch?: Batch;
}

export interface LabelsListParams {
  search?: string;
  mutations?: Mutation[];
  paginate?: false;
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})
export class LabelService {

  constructor(private api: ApiCallService) { }

  public create(params: StoreLabelParams) {
    return this.api.call('app.label.store', params);
  }

  public view(params: LabelParams, page?: number) {
    return this.api.call('app.label.view', params, page);
  }

  public update(params: UpdateLabelParams) {
    return this.api.call('app.label.update', params);
  }

  public delete(params: LabelParams) {
    return this.api.call('app.label.delete', params);
  }

  public list(params?: LabelsListParams, page?: number) {
    return this.api.call('app.label.list', params, page);
  }

  public getPublished(params: PublishedLabelParams) {
    return this.api.call('app.label.getPublished', params);
  }

  public publish(params?: { batch?: Batch }) {
    return this.api.call('app.label.publish');
  }
}
