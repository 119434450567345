import { Injectable } from '@angular/core';
import { ApiCallService, File, Mutation, Batch } from '../api-call.service';
import { DatePipe } from '@angular/common';


export interface ListFlightsParams {
  pricing?: boolean;
  with?: string[];
  paginate?: boolean;
  mutations?: Mutation[];
  batch?: Batch;
}

export interface TopFlightsParams {
  mutations?: Mutation[];
  batch?: Batch;
}

export interface CreateFlightParams {
  number: number;
  type?: string;
  passengers?: number;
  date?: Date | string;
  time?: string;
  aircraft: number;
  route: number[];
  batch?: Batch;
}

export interface UpdateFlightParams extends FlightParams, CreateFlightParams { }

interface ExportFlightParams {
  number: string;
  returnNumber: string;
  date_from: Date | string;
  date_to: Date | string;
  batch?: Batch;
}

export interface FlightParams {
  id: number;
  batch?: Batch;
}
export interface ListAircraftPricesParams {
  aircraft_id: number;
  batch?: Batch;
}

export interface CreateAircraftPriceParams {
  aircraft_id: number;
  value: number;
  starts_at?: string;
  batch?: Batch;
}

export interface UpdateAircraftPriceParams extends CreateAircraftPriceParams {
  id: number;
}

export interface ViewFlightParams extends FlightParams {
  with: string[];
}

export interface RoutePriceParams {
  route: number;
  date: Date | string;
  batch?: Batch;
}

export interface UpdateRoutePriceParams extends RoutePriceParams {
  aircraft_model: string;
  pricelist_id: number;
  price: number;
}

export interface AttachFilesParams extends FlightParams {
  files: Array<File>;
}

export interface UpdateMarginsParams extends FlightParams {
  legDirectExpenseMargin?: number;
  legExpenseMargin?: number;
}

@Injectable({
  providedIn: 'root'
})
export class FlightService {

  constructor(private api: ApiCallService) { }

  public list(params?: ListFlightsParams, page?) {
    return this.api.call('airline.flight.list', params, page);
  }

  public top(params?: TopFlightsParams) {
    return this.api.call('airline.flight.top', params);
  }

  public listByRoute(params?: ListFlightsParams, page?) {
    return this.api.call('airline.flight.listByRoute', params, page);
  }

  public view(params: ViewFlightParams) {
    return this.api.call('airline.flight.view', params);
  }

  public create(params: CreateFlightParams) {
    params.date = new DatePipe('en-GB').transform(params.date, 'yyyy-MM-dd');
    return this.api.call('airline.flight.store', params);
  }

  public viewRoutePrices(params: RoutePriceParams) {
    params.date = new DatePipe('en-GB').transform(params.date, 'yyyy-MM-dd');
    return this.api.call('airline.flight.viewRoutePrices', params);
  }
  public updateRoutePrice(params: UpdateRoutePriceParams) {
    params.date = new DatePipe('en-GB').transform(params.date, 'yyyy-MM-dd');
    return this.api.call('airline.flight.updateRoutePrice', params);
  }

  public update(params: UpdateFlightParams) {
    return this.api.call('airline.flight.update', params);
  }

  public export(params: ExportFlightParams) {
    params.date_from = new DatePipe('en-GB').transform(params.date_from, 'yyyy-MM-dd');
    params.date_to = new DatePipe('en-GB').transform(params.date_to, 'yyyy-MM-dd');
    return this.api.call('airline.flight.export', params);
  }

  public attachFiles(params: AttachFilesParams) {
    return this.api.call('airline.flight.attachFiles', params);
  }

  // Aircraft prices
  public listAircraftPrices(params?: ListAircraftPricesParams, page?) {
    return this.api.call('airline.flight.listAircraftPrices', params, page);
  }

  public storeAircraftPrice(params: CreateAircraftPriceParams) {
    params.starts_at = new DatePipe('en-GB').transform(params.starts_at, 'yyyy-MM-dd');
    return this.api.call('airline.flight.storeAircraftPrice', params);
  }

  public updateAircraftPrice(params: UpdateAircraftPriceParams) {
    params.starts_at = new DatePipe('en-GB').transform(params.starts_at, 'yyyy-MM-dd');
    return this.api.call('airline.flight.updateAircraftPrice', params);
  }
  public updateMargins(params: UpdateMarginsParams) {
    return this.api.call('airline.flight.updateMargins', params);
  }
}
