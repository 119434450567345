import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ApiCallService, File, Mutation, Translations, Batch } from '../api-call.service';

interface BannerItemParams {
  status: boolean;
  start_date?: Date;
  start_time?: string;
  categories?: number;
  end_date?: Date;
  end_time?: string;
  url?: string;
  order?: number;
  file: File;
  translations: Translations;
}
interface BannerParams {
  id: number;
  batch?: Batch;
}
interface ViewBannerParams extends BannerParams {
  with: string[];
}
interface ListBannerParams extends ViewBannerParams {
  mutations?: Mutation[];
}
interface CreateBannerParams {
  status: boolean;
  name: string;
  code?: string;
  type?: string;
  start_date?: Date;
  start_time?: string;
  end_date?: Date;
  end_time?: string;
  items?: BannerItemParams[];
  batch?: Batch;
}
interface UpdateBannerParams extends BannerParams, CreateBannerParams { }

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(private api: ApiCallService) { }

  public list(params?: ListBannerParams, page?) {
    return this.api.call('banner.banner.list', params, page);
  }

  private prepDates(params) {
    if (params.start_date && params.start_time) {
      const [hours, minutes] = params.start_time.split(':');
      params.start_date = new DatePipe('en-GB').transform(
        params.start_date.setHours(hours, minutes), 'yyyy-MM-dd HH:mm'
      );
      delete params.start_time;
    }
    if (params.end_date && params.end_time) {
      const [hours, minutes] = params.end_time.split(':');
      params.end_date = new DatePipe('en-GB').transform(
        params.end_date.setHours(hours, minutes), 'yyyy-MM-dd HH:mm'
      );
      delete params.end_time;
    }
    if (typeof params.items !== 'undefined') {
      for (const item of params.items) {
        if (item.start_date && item.start_time) {
          const [hours, minutes] = item.start_time.split(':');
          item.start_date = new DatePipe('en-GB').transform(
            item.start_date.setHours(hours, minutes), 'yyyy-MM-dd HH:mm'
          );
          delete item.start_time;
        }
        if (item.end_date && item.end_time) {
          const [hours, minutes] = item.end_time.split(':');
          item.end_date = new DatePipe('en-GB').transform(
            item.end_date.setHours(hours, minutes), 'yyyy-MM-dd HH:mm'
          );
          delete item.end_time;
        }
      }
    }
  }
  public create(params: CreateBannerParams) {
    this.prepDates(params);
    return this.api.call('banner.banner.store', params);
  }

  public view(params: ViewBannerParams) {
    return this.api.call('banner.banner.view', params);
  }

  public update(params: UpdateBannerParams) {
    this.prepDates(params);
    return this.api.call('banner.banner.update', params);
  }

  public delete(params: BannerParams) {
    return this.api.call('banner.banner.delete', params);
  }
}
