import { Injectable } from '@angular/core';
import { ApiCallService, Mutation, Batch } from '../api-call.service';

export interface PermissionParams {
  id: number;
  batch?: Batch;
}

export interface ListPermissionsParams {
  mutations?: Mutation[];
  batch?: Batch;
}

export interface ViewPermissionParams extends PermissionParams {
  with: Array<string>;
}

export interface CreatePermissionParams {
  module: string;
  controller: string;
  method: string;
  options?: string;
  batch?: Batch;
}

export interface UpdatePermissionParams extends PermissionParams {
  module?: string;
  controller?: string;
  method?: string;
  options?: string;
  attachRoles?: Array<number>;
  detachRoles?: Array<number>;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private api: ApiCallService) { }

  public list(params?: ListPermissionsParams, page?: number) {
    return this.api.call('access.permission.list', params, page);
  }

  public view(params: ViewPermissionParams) {
    return this.api.call('access.permission.view', params);
  }

  public create(params: CreatePermissionParams) {
    return this.api.call('access.permission.store', params);
  }

  public update(params: UpdatePermissionParams) {
    return this.api.call('access.permission.update', params);
  }
  public delete(params: PermissionParams) {
    return this.api.call('access.permission.delete', params);
  }
}
