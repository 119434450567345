import { Injectable } from '@angular/core';
import { ApiCallService, Batch } from '../api-call.service';

interface BatchParam {
  batch?: Batch;
}
interface CheckoutCartParams extends BatchParam {
  hash: string;
}
interface CheckoutProductParams extends BatchParam {
  product_id: number;
}
interface CheckoutDeliveryParams extends BatchParam {
  id: number;
}
interface CheckoutInvoiceParams extends BatchParam {
  id: number;
}
interface CheckoutAddressParams extends BatchParam {
  id: number;
}
interface CheckoutPaymentMethodParams extends BatchParam {
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private api: ApiCallService) { }

  public cart(params?: CheckoutCartParams) {
    return this.api.call('checkout.checkout.cart', params);
  }
  public product(params?: CheckoutProductParams) {
    return this.api.call('checkout.checkout.product', params);
  }
  public identify(params?: BatchParam) {
    return this.api.call('checkout.checkout.identify', params);
  }
  public setDelivery(params?: CheckoutDeliveryParams) {
    return this.api.call('checkout.checkout.setDelivery', params);
  }
  public setInvoice(params?: CheckoutInvoiceParams) {
    return this.api.call('checkout.checkout.setInvoice', params);
  }
  public unsetInvoice() {
    return this.api.call('checkout.checkout.unsetInvoice');
  }
  public setAddress(params?: CheckoutAddressParams) {
    return this.api.call('checkout.checkout.setAddress', params);
  }
  public setPaymentMethod(params?: CheckoutPaymentMethodParams) {
    return this.api.call('checkout.checkout.setPaymentMethod', params);
  }
  public order(params?: BatchParam) {
    return this.api.call('checkout.checkout.order', params);
  }
}
