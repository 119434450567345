import { Injectable } from '@angular/core';
import {
	ApiCallService,
	Mutation
} from '../api-call.service';

interface ListParams {
  account_id: number;
  paginate?: boolean;
  mutations?: Mutation[];
}

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  protected module = 'wallet';
	protected controller = 'account';

  constructor(private api: ApiCallService) { }

  public list(params?: ListParams, page?) {
    return this.api.call(
      'wallet.transaction.list', 
      params, 
      page
    );
  }
}
