import { Injectable } from '@angular/core';
import { ApiCallService, Mutation } from '../api-call.service';

interface ListCurrencyParams {
  mutations: Mutation[];
}

interface ViewCurrencyParams {
  id: number;
}

interface CreateCurrencyParams {
  status: number;
  code: string;
}

interface UpdateCurrencyParams extends ViewCurrencyParams, CreateCurrencyParams { }

interface DeleteCurrencyParams {
  id: number;
}
@Injectable({
  providedIn: 'root'
})
export class PricingCurrencyService {

  constructor(private api: ApiCallService) { }

  public list(params: ListCurrencyParams, page?) {
    return this.api.call('pricing.currency.list', params, page);
  }

  public view(params: ViewCurrencyParams) {
    return this.api.call('pricing.currency.view', params);
  }

  public create(params: CreateCurrencyParams) {
    return this.api.call('pricing.currency.store', params);
  }

  public update(params: UpdateCurrencyParams) {
    return this.api.call('pricing.currency.update', params);
  }

  public delete(params: DeleteCurrencyParams) {
    return this.api.call('pricing.currency.delete', params);
  }
}
