import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  protected methodPrefix

  constructor(protected api: ApiCallService) { }

  protected proxy() {
    const proxy = new Proxy(this, {
      get: function (service, method: string) {
        if (method in service) return service[method];
        return function (params?, page?) {
          return service.api.call([service.methodPrefix, method].join('.'), params, page)
        }
      }
    });
    return proxy;
  }
}
