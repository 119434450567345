import { Injectable } from '@angular/core';
import { ApiCallService, File, Translations } from '../api-call.service';

interface ListCarrierParams {
  with: string[];
}

interface ViewCarrierParams extends ListCarrierParams {
  id: number;
}

interface CreateCarrierParams {
  status: number;
  code: string;
  translations: Translations;
  type: string;
  image: File;
}

interface UpdateCarrierParams {
  status: number;
  code: string;
  translations: Translations;
  type: string;
  new_image: File;
}

interface DeleteCarrierParams {
  id: number;
}

interface CreateRuleParams extends UpdateRuleParams {
  carrier_id: number;
}

export interface UpdateRuleParams {
  id: number;
  condition_from?: number;
  condition_to?: number;
  price?: number;
  currency_id?: number;
  zone_id?: number | '';
  default: boolean;
}

export interface DeleteRuleParams {
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  constructor(private api: ApiCallService) { }

  public list(params: ListCarrierParams, page?: any) {
    return this.api.call('delivery.carrier.list', params, page);
  }

  public view(params: ViewCarrierParams) {
    return this.api.call('delivery.carrier.view', params);
  }

  public create(params: CreateCarrierParams) {
    return this.api.call('delivery.carrier.store', params);
  }

  public update(params: UpdateCarrierParams) {
    return this.api.call('delivery.carrier.update', params);
  }

  public delete(params: DeleteCarrierParams) {
    return this.api.call('delivery.carrier.delete', params);
  }

  public createRule(params: CreateRuleParams) {
    params.zone_id = (params.zone_id === '') ? null : params.zone_id;

    return this.api.call('delivery.carrier.storeRule', params);
  }

  public updateRule(params: UpdateRuleParams) {
    params.zone_id = (params.zone_id === '') ? null : params.zone_id;

    return this.api.call('delivery.carrier.updateRule', params);
  }

  public deleteRule(params: DeleteRuleParams) {
    return this.api.call('delivery.carrier.deleteRule', params);
  }
}
