import { Injectable } from '@angular/core';
import {
  ApiCallService,
  Mutation,
  Translations,
  Batch
} from '../api-call.service';


export interface CategoryParams {
  id: number;
  batch?: Batch;
}

export interface ViewCategoryParams extends CategoryParams {
  with: string[];
}

export interface CreateCategoryParams {
  code?: string;
  status: boolean;
  order: number;
  extended_by?: string;
  parent_id?: number;
  translations: Translations;
  [key: string]: any;
  batch?: Batch;
}

export interface ListCategoryParams {
  search?: string;
  paginate?: boolean;
  root?: number;
  list: 'tree' | 'flatTree' | 'list';
  mutations?: Mutation[];
  batch?: Batch;
}

export interface UpdateCategotyParams extends CategoryParams, CreateCategoryParams { }

interface NomenclaturesParams {
  id: number;
  batch?: Batch;
}

export interface NomenclatureSyncParams {
  id: number;
  nomenclatures: { [key: number]: any };
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})

export class CategoryService {

  constructor(private api: ApiCallService) { }

  public list(params?: ListCategoryParams, page?) {
    return this.api.call('category.category.list', params, page);
  }

  public create(params: CreateCategoryParams) {
    return this.api.call('category.category.store', params);
  }

  public view(params: ViewCategoryParams) {
    return this.api.call('category.category.view', params);
  }

  public update(params: UpdateCategotyParams) {
    return this.api.call('category.category.update', params);
  }

  public delete(params: CategoryParams) {
    return this.api.call('category.category.delete', params);
  }

  public nomenclatures(params?: NomenclaturesParams) {
    return this.api.call('category.category.nomenclatures', params);
  }

  public nomeclatureSync(params: NomenclatureSyncParams) {
    const selected = {};
    for (const [key, value] of Object.entries(params.nomenclatures)) {
      if (!!value) {
        selected[key] = {
          order: value.order,
          groups: value.value
        };
      }
    }
    params.nomenclatures = selected;
    return this.api.call('category.category.nomeclatureSync', params);
  }
}
