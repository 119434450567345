import { Injectable } from '@angular/core';
import { ApiCallService, Batch } from '../api-call.service';

export interface MenuParams {
  id: number;
  batch?: Batch;
}

export interface CreateMenuParams {
  code: string;
  status: boolean;
  name: string;
  batch?: Batch;
}

export interface UpdateMenuParams extends MenuParams {
  code?: string;
  status?: boolean;
  name?: string;
}

export interface ViewMenuParams extends MenuParams {
  with?: string[];
}

export interface ItemParams {
  menu_id: number;
  id?: number;
  batch?: Batch;
}

interface SaveItemsParams {
  menu_id: number;
  items: Array<{ [key: string]: any }>;
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private api: ApiCallService) { }

  public list(params?, page?) {
    return this.api.call('menu.menu.list', params, page);
  }

  public create(params: CreateMenuParams) {
    return this.api.call('menu.menu.store', params);
  }

  public view(params: ViewMenuParams) {
    return this.api.call('menu.menu.view', params);
  }

  public update(params: UpdateMenuParams) {
    return this.api.call('menu.menu.update', params);
  }

  public delete(params: MenuParams) {
    return this.api.call('menu.menu.delete', params);
  }

  public saveItems(params: SaveItemsParams) {
    return this.api.call('menu.menu.saveItems', params);
  }

  public deleteItem(params: ItemParams) {
    return this.api.call('menu.menu.deleteItem', params);
  }
}
