import { Injectable } from '@angular/core';
import { ApiCallService, Mutation, Batch } from '../api-call.service';
import { DatePipe } from '@angular/common';
import { map } from 'rxjs';

interface ListOrderParams {
  with?: string[];
  paginate?: boolean;
  mutations?: Mutation[];
  batch?: Batch;
}

interface OrderParams {
  id: number;
  batch?: Batch;
}

interface ViewOrderParams extends OrderParams {
  with: string[];
}

interface OrderItemParams {
  id?: number;
  product_id: number;
  quantity?: number;
  sell_price: number;
  discount_amount?: number;
  discount_quantity?: number;
  discount_percent?: number;
}

interface CreateOrderParams {
  status: boolean;
  comment?: string;
  user_id?: number;
  company_id?: number;
  created_at?: string;
  items: OrderItemParams[];
  batch?: Batch;
}

interface UpdateOrderParams extends OrderParams, CreateOrderParams { }


@Injectable({
  providedIn: 'root'
})

export class OrderService {
  constructor(private api: ApiCallService) { }

  public list(params?: ListOrderParams, page?) {
    if (typeof params.mutations !== 'undefined') {
      const dateClause: any = params.mutations.find(m => {
        return m.constraint === 'whereBetween' &&
               m.params.indexOf('created_at') !== -1;
      });

      if (dateClause) {
        dateClause.params[1][1]  += ' 23:59:59';
      }
    }

    return this.api.call('order.order.list', params, page);
  }
  public view(params: ViewOrderParams) {
    return this.api.call('order.order.view', params);
  }
  public create(params: CreateOrderParams) {
    params.created_at = new DatePipe('en-GB').transform(
      params.created_at,
      'yyyy-MM-dd HH:mm:ss'
    );
    return this.api.call('order.order.store', params);
  }
  public update(params: UpdateOrderParams) {
    params.created_at = new DatePipe('en-GB').transform(
      params.created_at,
      'yyyy-MM-dd HH:mm:ss'
    );
    return this.api.call('order.order.update', params);
  }
  public delete(params: OrderParams) {
    return this.api.call('order.order.delete', params);
  }
  public orderIncome(params) {
    const result = this.list(params);
    return result.pipe(map((response: any) => {
      let incomePerWeekDays = [];

      response.result.data.forEach((item) => {
        let date = item.created_at.split('T')[0];
        let dayIncome = {
          name: date,
          value: item.total_amount
        }
        const incomeDay = incomePerWeekDays.find((d) => d.name === dayIncome.name)
        if(!incomeDay) {
          incomePerWeekDays.push(dayIncome);
        } else {
          incomeDay.value += item.total_amount;
        }
      });

      response.result.data = incomePerWeekDays;
      return response
    }));
  }

}
