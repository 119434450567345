import { Injectable } from '@angular/core';
import {
	ApiCallService,
	Mutation
} from '../api-call.service';

interface DebitParms {
	id: number;
	amount: number
}

interface ListParams {
	user_id: number;
	paginate?: boolean;
	mutations?: Mutation[];
}

interface ViewParams {
	id: number;
	paginate?: boolean;
	mutations?: Mutation[];
}

@Injectable({
	providedIn: 'root'
})
export class AccountService {

	protected module = 'wallet';
	protected controller = 'account';

	constructor(private api: ApiCallService) { }

	public debit(params?: DebitParms) {
		return this.api.call(
			this.module + '.' + this.controller + '.debit',
			params
		);
	}

	public list(params?: ListParams, page?) {
		return this.api.call(
			'wallet.account.list', 
			params, 
			page
		);
	}

	public view(params?: ViewParams, page?) {
		return this.api.call(
			'wallet.account.view', 
			params, 
			page
		);
	}
}
