import { Injectable } from '@angular/core';
import { ApiCallService, Batch, Mutation, SearchParams } from '../api-call.service';

interface FilterParams {
  id: number;
  batch?: Batch;
}
interface ViewFilterParams extends FilterParams {
  with: string[];
}

interface ListFilterParams extends ViewFilterParams {
  mutations?: Mutation[];
}

interface FilterItemParams {
  nomenclature_id: number;
  order?: boolean;
  search_key: string;
  component: string;
  class: string;
}

interface CreateFilterParams {
  status: boolean;
  name: string;
  default: boolean;
  categories: number[];
  items: FilterItemParams[];
}

interface UpdateFilterParams extends FilterParams, CreateFilterParams { }


@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor(private api: ApiCallService) { }

  public create(params: CreateFilterParams) {
    return this.api.call('filter.filter.store', params);
  }

  public list(params: ListFilterParams) {
    return this.api.call('filter.filter.list', params);
  }

  public view(params: ViewFilterParams) {
    return this.api.call('filter.filter.view', params);
  }

  public update(params: UpdateFilterParams) {
    return this.api.call('filter.filter.update', params);
  }

  public delete(params: FilterParams) {
    return this.api.call('filter.filter.delete', params);
  }

  public render(params: SearchParams) {
    return this.api.call('filter.filter.render', params);
  }

  public getFilterConfiguration(params) {
    return this.api.call('filter.filter.getFilterConfiguration', params);
  }
}
